
import Footer from "../../component/footer/footer"
import NavBar from "../../component/navbar/navbar"
import { NavLink } from "react-router-dom"

import styles from "./tarifs.module.scss"

function TarifsPage() {
    return(
        <div>
            <NavBar/>
            <section className={styles.firstsection}>
                <h3>tarifs</h3>
                <div className={styles.abonnementContainer}>
                    <div className={styles.sansEngagementContainer}>
                        <div className={styles.sansEngagementHeader}>
                            <div className={styles.triangle}/>
                            <h4>Sans engagement</h4>
                        </div>
                        <div className={styles.sansEngagementBody}>
                            <div className={styles.sansEngagementTableau}>
                                <div className={styles.mois}><h5>Mensuel</h5></div>
                                <div className={styles.adulte}><h6>Adulte</h6></div>
                                <div className={styles.etudiant}><h6>Etudiant</h6></div>
                                <div className={styles.tarrifsadulte}><p>84 €</p></div>
                                <div className={styles.tarrifsetudiant}><p>74 €</p></div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.avecEngagementContainer}>
                        <div className={styles.avecEngagementHeader}>
                            <div className={styles.triangle}/>
                            <h4>Avec engagement</h4>
                        </div>
                        <div className={styles.avecEngagementBody}>
                            <div className={styles.avecEngagementTableau}>
                                <div className={styles.troismois}><h5>3 mois</h5></div>
                                <div className={styles.sixmois}><h5>6 mois</h5></div>
                                <div className={styles.douzemois}><h5>Annuel</h5></div>
                                <div className={styles.adultetroismois}><p>79 €</p></div>
                                <div className={styles.adultesixmois}><p>74 €</p></div>
                                <div className={styles.adultedouzemois}><p>68 €</p></div>
                                <div className={styles.etudianttroismois}><p>69 €</p></div>
                                <div className={styles.etudiantsixmois}><p>64 €</p></div>
                                <div className={styles.etudiantdouzemois}><p>58 €</p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.formulesContainer}>
                    <div className={styles.formulesCard}>
                        <div className={styles.formulesHeader}>
                            <div className={styles.triangle}/>
                            <h4>Formules</h4>
                        </div>
                        <div className={styles.formulesBody}>
                            <div className={styles.formulesTableau}>
                                <div className={styles.dropin}><h5>Drop in *</h5></div>
                                <div className={styles.dixseances}><h5>10 séances</h5></div>
                                <div className={styles.cinquanteseances}><h5>50 séances</h5></div>
                                <div className={styles.teenseance}><h5>1 séance Teens</h5></div>
                                <div className={styles.dropinprix}><p>12 €</p></div>
                                <div className={styles.dixseancesprix}><p>110 €</p></div>
                                <div className={styles.cinquanteseancesprix}><p>500 €</p></div>
                                <div className={styles.teenseanceprix}><p>6 €</p></div>
                            </div>
                            <h7>*Drop in = 1 séance</h7>
                        </div>
                    </div>
                </div>
                <div className={styles.essaieContainer}>
                    <h4>Séance d'essai GRATUITE</h4>
                    <NavLink to="/contacts">sur réservation</NavLink>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default TarifsPage